import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyProps } from '@mui/material';
import MaterialUITypography from './Typography';

const subtitle2PropertyTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.string,
  tag: PropTypes.string,
};

export type Subtitle2PropertyTypes = InferProps<
typeof subtitle2PropertyTypes
>;

export type Subtitle2Properties = Omit<InferProps<
typeof subtitle2PropertyTypes
>, 'tag'> & TypographyProps & {
  tag?: React.ElementType;
  variant?: Variant;
};

const Subtitle2 = ({
  children, tag, className, variant,
}: Subtitle2Properties) => (
  <MaterialUITypography
    tag={tag ?? 'span' as React.ElementType}
    className={className ?? undefined}
    variant={variant ?? 'subtitle2'}
    color="textSecondary"
  >
    {children}
  </MaterialUITypography>
);

Subtitle2.propTypes = subtitle2PropertyTypes;

export default Subtitle2;
